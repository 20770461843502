import { authPost, doGet } from "../../common/redux/ApiClient";

const baseUrl = "http://34.87.51.38/ok99memberApi/api";

export function fetchLotteryResultUI() {
  return doGet(`${baseUrl}/Member5/LotteryResultUI`, {});
}

export function buyLottery(queryParams) {
  return authPost(`${baseUrl}/Member6/VBossBuy4D`, queryParams);
}

export function fetchLotteryList(queryParams) {
  return authPost(`${baseUrl}/Member6/VBossSlipList`, queryParams);
}

export function fetchLotteryDetail(queryParams) {
  return authPost(`${baseUrl}/Member6/VBossSlipDetail`, queryParams);
}

export function voidLottery(queryParams) {
  return authPost(`${baseUrl}/Member6/VBossSlipVoid`, queryParams);
}

export function fetchLotteryHistory(queryParams) {
  return authPost(`${baseUrl}/Member6/VBossHistory`, queryParams);
}

export function fetchLotteryHistoryDetail(queryParams) {
  return authPost(`${baseUrl}/Member6/VBossHistoryDetail`, queryParams);
}

export function fetchLotteryPrizeUI() {
  return authPost(`${baseUrl}/Member7/VBossPrizeUI`, {});
}

export function reorderLottery(queryParams) {
  return authPost(`${baseUrl}/Member7/VBossReorder`, queryParams);
}

export function fetchCompanyGameBalance(queryParams) {
  return authPost(`${baseUrl}/Member3/CompanyGameBalance`, queryParams);
}

export function fetchLotteryUI(queryParams) {
  return authPost(`${baseUrl}/Member7/VBossBuyUI`, queryParams);
}
