import { registerLocale } from "react-datepicker";
import { useIntl } from "react-intl";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { MasterLayout } from "../modules/common/components/MasterLayout";
import { TelegramListing } from "../modules/telegram/component/TelegramListing";
import { AppDownloadWrapper } from "../pages/appDownload/AppDownloadWrapper";
import { AppGamesWrapper } from "../pages/appGames/AppGamesWrapper";
import { FishingWrapper } from "../pages/fishing/FishingWrapper";
import { GamePlayWrapper } from "../pages/gamePlay/GamePlayWrapper";
import { HomeWrapper } from "../pages/home/HomeWrapper";
import { LiveCasinoWrapper } from "../pages/liveCasino/LiveCasinoWrapper";
import { LotteryWrapper } from "../pages/lottery/LotteryWrapper";
import { PromotionWrapper } from "../pages/promotion/PromotionWrapper";
import { SettingWrapper } from "../pages/setting/SettingWrapper";
import { SlotWrapper } from "../pages/slot/SlotWrapper";
import { SportWrapper } from "../pages/sport/SportWrapper";
import { getDateLocale } from "../utils/DateLocale";

const PrivateRoutes = () => {
  const intl = useIntl();
  let locale = intl.locale;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const platform = queryParams.get("platform");

  registerLocale(locale, getDateLocale(locale));

  return (
    <>
      <Routes>
        {platform === "telegram" ? (
          <Route path="*" element={<TelegramListing />} />
        ) : (
          <Route element={<MasterLayout />}>
            <Route path="home/*" element={<HomeWrapper />} />
            <Route path="slot/*" element={<SlotWrapper />} />
            <Route path="liveCasino/*" element={<LiveCasinoWrapper />} />
            <Route path="fishing/*" element={<FishingWrapper />} />
            <Route path="appsGame/*" element={<AppGamesWrapper />} />
            <Route path="setting/*" element={<SettingWrapper />} />
            <Route path="gamePlay/*" element={<GamePlayWrapper />} />
            <Route path="lottery/*" element={<LotteryWrapper />} />
            <Route path="promotion/*" element={<PromotionWrapper />} />
            <Route path="sport/*" element={<SportWrapper />} />
            <Route path="appDownload/*" element={<AppDownloadWrapper />} />
            <Route path="*" element={<Navigate to={"/home"} />} />
          </Route>
        )}
      </Routes>
    </>
  );
};

export { PrivateRoutes };
