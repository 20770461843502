import { useRef } from "react";
import { useIntl } from "react-intl";
import { CopyToClipboard } from "../../../utils/CommonHelper";
import { Modal } from "./Modal";

export const ContactUsModal = ({ setSwalProps }) => {
  const dismissBtnRef = useRef();
  const intl = useIntl();

  return (
    <Modal
      modalId="liveChatModal"
      title={intl.formatMessage({ id: "liveChat" })}
      dismissBtnRef={dismissBtnRef}
    >
      <div className="p-4">
        <div className="d-flex align-items-center mb-2">
          <div className="pe-3 pb-2">
            <i className="bi bi-telegram fs-1 text-primary" />
          </div>
          <div>
            <div>
              <a href="https://t.me/at279RCcustomerservice" target="_blank">
                https://t.me/at279RCcustomerservice
              </a>
            </div>
            <div>
              +6017-9916753{" "}
              <i
                className="ms-2 fa fa-copy cursor-pointer"
                onClick={() =>
                  CopyToClipboard("+6017-9916753", setSwalProps, intl)
                }
              />
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div className="pe-3 pb-2">
            <i className="bi bi-whatsapp fs-1 text-success" />
          </div>
          <div>
            <div>
              <a href="https://wa.me/+60176814148" target="_blank">
                https://wa.me/+60176814148
              </a>
            </div>
            <div>
              +6017-6814148{" "}
              <i
                className="ms-2 fa fa-copy cursor-pointer"
                onClick={() =>
                  CopyToClipboard("+6017-6814148", setSwalProps, intl)
                }
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
