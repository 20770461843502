import CryptoJS from "crypto-js";
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { Field, Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { RegisterTypeConst } from "../../../../constants/RegisterTypeConst";
import { ApiStatusTypeEnum } from "../../../../enumeration/ApiStatusTypeEnum";
import { I18N_CONFIG_KEY } from "../../../../i18n/Metronici18n";
import { firebaseConfig } from "../../../../setup/FirebaseConfig";
import { CheckIsNull, IsAlphaNumeric } from "../../../../utils/CommonHelper";
import { Input } from "../../../common/components/control/InputField";
import { LanguageList } from "../../../common/components/LanguageList";
import { Modal } from "../../../common/components/Modal";
import { TermsAndCondition } from "../../../common/components/TermsAndCondition";
import { LoginTab } from "../../enum/LoginTab";
import { fetchIPAddress, googleLogin, login } from "../../redux/LoginCRUD";
import { LoginSchema } from "../../yupSchema/loginSchema";
import { useAuth } from "../AuthInit";

export const LoginFormTab = ({
  setSwalProps,
  setLoginTab,
  setTempAuth,
  credential,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const longitude = useRef("");
  const latitude = useRef("");
  const [isLoading, setIsLoading] = useState(false);
  const [isGoogleLoading, setIsGoogleLoading] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth();

  // useEffect(() => {
  //   navigator.geolocation.getCurrentPosition(function (position) {
  //     longitude.current = position.coords.longitude;
  //     latitude.current = position.coords.latitude;
  //   });
  // }, []);

  const googleAuthLogin = () => {
    setIsGoogleLoading(true);
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    const provider = new GoogleAuthProvider();
    provider.addScope("https://www.googleapis.com/auth/userinfo.profile");
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;

        fetchIPAddress()
          .then((res) => {
            const queryParams = {
              email: user.email,
              language: localStorage.getItem(I18N_CONFIG_KEY)
                ? intl.locale
                : "",
              uid: user.uid,
              mediaplatform: "gmail",
              fcmnotificationid: "",
              latitude: latitude.current,
              longitude: longitude.current,
              ipaddress: res.ip,
            };
            googleLogin(queryParams)
              .then((resp) => {
                setIsGoogleLoading(false);
                if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
                  saveAuth(resp);
                  setCurrentUser(resp);
                } else {
                  setSwalProps({
                    show: true,
                    icon: "error",
                    title: intl.formatMessage({ id: "error" }),
                    text: resp.message,
                  });
                }
              })
              .catch((error) => {
                setIsGoogleLoading(false);
              });
          })
          .catch((error) => {
            setIsGoogleLoading(false);
          });
      })
      .catch((error) => {
        setIsGoogleLoading(false);
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: intl.formatMessage({ id: "failToLogin" }),
        });
      });
  };

  useEffect(() => {
    if (credential) {
      const decodeCredential = JSON.parse(
        CryptoJS.AES.decrypt(
          atob(decodeURIComponent(credential)),
          "279rc#!@279rc"
        ).toString(CryptoJS.enc.Utf8)
      );

      if (
        decodeCredential &&
        decodeCredential.username &&
        decodeCredential.password
      ) {
        fetchIPAddress()
          .then((res) => {
            const queryParams = {
              username: decodeCredential.username,
              password: decodeCredential.password,
              language: intl.locale,
              fcmnotificationid: "",
              longitude: "",
              latitude: "",
              ipaddress: res.ip,
            };
            login(queryParams)
              .then((resp) => {
                setIsLoading(false);
                if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
                  if (
                    resp.registerby === RegisterTypeConst.TEL_NO &&
                    CheckIsNull(resp.telno)
                  ) {
                    setTempAuth(resp);
                    setLoginTab(LoginTab.LOGIN_PHONE_AUTH_STEP_1);
                  } else {
                    saveAuth(resp);
                    setCurrentUser(resp);
                  }
                } else {
                  setSwalProps({
                    show: true,
                    icon: "error",
                    title: intl.formatMessage({ id: "error" }),
                    text: resp.message,
                  });
                }
              })
              .catch((error) => {
                setIsLoading(false);
              });
          })
          .catch((error) => {
            setIsLoading(false);
          });
      }
    }
  }, [credential]);

  return (
    <>
      <Modal
        modalId="termsAndConditionModal"
        title={intl.formatMessage({ id: "termsAndCondition" })}
        modalSize="modal-lg"
      >
        <div className="p-3">
          <TermsAndCondition />
        </div>
      </Modal>
      <Formik
        initialValues={{ username: "", userPassword: "" }}
        enableReinitialize={true}
        validationSchema={LoginSchema(intl)}
        onSubmit={(values, formikHelpers) => {
          setIsLoading(true);
          fetchIPAddress()
            .then((res) => {
              const queryParams = {
                username: values.username,
                password: values.userPassword,
                language: intl.locale,
                fcmnotificationid: "",
                longitude: longitude.current,
                latitude: latitude.current,
                ipaddress: res.ip,
              };
              login(queryParams)
                .then((resp) => {
                  setIsLoading(false);
                  if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
                    if (
                      resp.registerby === RegisterTypeConst.TEL_NO &&
                      CheckIsNull(resp.telno)
                    ) {
                      setTempAuth(resp);
                      setLoginTab(LoginTab.LOGIN_PHONE_AUTH_STEP_1);
                    } else {
                      saveAuth(resp);
                      setCurrentUser(resp);
                    }
                  } else {
                    formikHelpers.setFieldValue("userPassword", "");
                    setSwalProps({
                      show: true,
                      icon: "error",
                      title: intl.formatMessage({ id: "error" }),
                      text: resp.message,
                    });
                  }
                })
                .catch((error) => {
                  setIsLoading(false);
                });
            })
            .catch((error) => {
              setIsLoading(false);
            });
        }}
      >
        {({
          values,
          handleSubmit,
          setFieldValue,
          touched,
          errors,
          setFieldError,
        }) => (
          <Form>
            <div className="mb-2">
              <Field
                name="username"
                component={Input}
                placeholder={intl.formatMessage({ id: "username" })}
                label={intl.formatMessage({ id: "username" })}
                autoComplete="off"
                onKeyDown={(e) => {
                  if (!IsAlphaNumeric(e)) {
                    e.preventDefault();
                  }
                }}
              />
            </div>
            <div className="mb-2">
              <Field
                name="userPassword"
                type="password"
                component={Input}
                placeholder={intl.formatMessage({ id: "password" })}
                label={intl.formatMessage({ id: "password" })}
                autoComplete="off"
                onKeyDown={(e) => {
                  if (!IsAlphaNumeric(e)) {
                    e.preventDefault();
                  }
                }}
              />
            </div>
            <div
              className="text-decoration-none text-prim cursor-pointer fw-bold"
              onClick={() => {
                setLoginTab(LoginTab.FORGOT_PASSWORD_PART_1);
              }}
            >
              {intl.formatMessage({ id: "forgotPassword" })} ?
            </div>
            <div className="mt-4 mb-2">
              <button
                type="submit"
                disabled={isLoading}
                className="btn btn-prim fw-bold w-100"
              >
                <div className="d-flex align-items-center justify-content-center">
                  {isLoading && (
                    <div
                      className="spinner-border text-dark"
                      role="status"
                      style={{ scale: "0.75" }}
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}

                  <div>{intl.formatMessage({ id: "logIn" })}</div>
                </div>
              </button>
            </div>
          </Form>
        )}
      </Formik>
      {/* <div className="divider my-4">
        {intl.formatMessage({ id: "orSignInWith" })}
      </div>
      <button
        type="button"
        disabled={isGoogleLoading}
        className="btn btn-custom-light w-100 position-relative fw-bold mb-4"
        onClick={() => googleAuthLogin()}
      >
        {isGoogleLoading ? (
          <div
            className="position-absolute translate-middle"
            style={{ left: "2rem", top: "52%" }}
          >
            <div
              className="spinner-border text-light"
              role="status"
              style={{ scale: "0.75" }}
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <img
            src={toAbsoluteUrl("images/button/google.png")}
            alt="google"
            className="position-absolute top-50 translate-middle"
            style={{ width: "20px", height: "20px", left: "2rem" }}
          />
        )}

        {intl.formatMessage({ id: "continueWithGoogle" })}
      </button> */}
      <div className="mt-4">
        <LanguageList />
      </div>
      <div className="fw-bold d-flex justify-content-center">
        <div className="me-2">{intl.formatMessage({ id: "noAccount" })} ?</div>
        <div
          className="text-decoration-none text-prim cursor-pointer"
          onClick={() => {
            setLoginTab(LoginTab.REGISTER_OPTION);
          }}
        >
          {intl.formatMessage({ id: "signUp" })}
        </div>
      </div>
      <div className="d-flex justify-content-center mt-4">
        <button
          type="button"
          className="btn btn-link"
          data-bs-toggle="modal"
          data-bs-target="#termsAndConditionModal"
          style={{ color: "white" }}
        >
          {intl.formatMessage({ id: "termsAndCondition" })}
        </button>
      </div>
    </>
  );
};
