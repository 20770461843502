/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { useAuth } from "../modules/auth/components/AuthInit";
import { AuthWrapper } from "../pages/auth/AuthWrapper";
import { PrivateRoutes } from "./PrivateRoutes";
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

const AppRoutes = () => {
  const { auth } = useAuth();

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        {auth ? (
          <>
            <Route path="/*" element={<PrivateRoutes />} />
            <Route index element={<Navigate to="/home" />} />
          </>
        ) : (
          <>
            <Route path="/*" element={<AuthWrapper />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
