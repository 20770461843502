import { toAbsoluteUrl } from "../../../utils/CommonHelper";

export const TelegramListing = () => {
  return (
    <div
      style={{ height: "100vh", gap: "0.75rem" }}
      className="d-flex flex-column align-items-center justify-content-center fs-5 fw-semibold px-4"
    >
      <img
        src={toAbsoluteUrl("images/logo.png")}
        alt="logo"
        className="w-100 mb-3"
        style={{ maxWidth: "200px" }}
      />
      <div className="text-center">Please open using mobile</div>
      <div className="text-center">请使用手机打开</div>
      <div className="text-center">Sila buka menggunakan telefon bimbit</div>
    </div>
  );
};
