import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import Skeleton from "react-loading-skeleton";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../../utils/CommonHelper";
import * as actions from "../../redux/LotteryAction";

export const LotteryResultListing = ({ isResultOnly }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const countDown = 30;
  const timerRef = useRef(countDown);
  const [timer, setTimer] = useState(countDown);

  useEffect(() => {
    if (isResultOnly) {
      document.getElementById("chat-widget-container")?.remove();
      document.getElementById("root")?.classList.add("overflow-hidden");
    }
    dispatch(actions.getLotteryResultUI());

    const fetchLotteryInterval = setInterval(() => {
      if (isResultOnly) {
        document.getElementById("chat-widget-container")?.remove();
      }
      if (timerRef.current > 0) {
        timerRef.current--;
        setTimer(timerRef.current);
      } else {
        timerRef.current = countDown;
        setTimer(timerRef.current);
        dispatch(actions.getLotteryResultUI());
      }
    }, 1000);

    return () => clearInterval(fetchLotteryInterval);
  }, []);

  const lotteryState = useSelector(({ lottery }) => lottery, shallowEqual);

  const LotteryCard = ({ lotteryResult }) => (
    <div className="col-md-4 px-3 mb-4">
      <div
        className={`bg-${lotteryResult.lotterycode} rounded custom-border overflow-hidden`}
      >
        <div
          className={`d-flex p-3 justify-content-between align-items-center custom-border-bottom lottery-head-${lotteryResult.lotterycode}`}
        >
          <div className="fw-bold">
            <div className="mb-1">
              {lotteryResult.lotteryname.toUpperCase()}
            </div>
            <div>{lotteryResult.drawdate}</div>
          </div>
          <img
            src={toAbsoluteUrl(
              `/images/lotteryCompany/${lotteryResult.lotterycode}.png`
            )}
            alt="magnum"
            style={{ height: "50px" }}
          />
        </div>
        <div style={{ fontSize: "1.1rem" }} className="fw-bold">
          <div className="d-flex p-3 custom-border-bottom justify-content-center align-items-center">
            <div className="text-end pe-1 text-muted fs-6">
              1ST {intl.formatMessage({ id: "prize" }).toUpperCase()}
            </div>
            <div className="text-start ps-1">{lotteryResult.firstprize}</div>
          </div>
          <div className="d-flex p-3 custom-border-bottom justify-content-center align-items-center">
            <div className="text-end pe-1 text-muted fs-6">
              2ND {intl.formatMessage({ id: "prize" }).toUpperCase()}
            </div>
            <div className="text-start ps-1">{lotteryResult.secondprize}</div>
          </div>
          <div className="d-flex p-3 custom-border-bottom justify-content-center align-items-center">
            <div className="text-end pe-1 text-muted fs-6">
              3RD {intl.formatMessage({ id: "prize" }).toUpperCase()}
            </div>
            <div className="text-start ps-1">{lotteryResult.thirdprize}</div>
          </div>
          <div className="custom-border-bottom">
            <div className="text-muted fs-6 text-center py-2">
              {intl.formatMessage({ id: "special" }).toUpperCase()}
            </div>
            <div className="row">
              {Object.entries(lotteryResult)
                .filter(([key, value]) => key.includes("special"))
                .map(([key, value]) => (
                  <div
                    className="mb-2 text-center"
                    key={key}
                    style={{ width: "20%" }}
                  >
                    {value}
                  </div>
                ))}
            </div>
          </div>
          <div>
            <div className="text-muted fs-6 text-center py-2">
              {intl.formatMessage({ id: "consolation" }).toUpperCase()}
            </div>
            <div className="row px-2">
              {Object.entries(lotteryResult)
                .filter(([key, value]) => key.includes("consolation"))
                .map(([key, value]) => (
                  <div
                    className="mb-2 text-center"
                    key={key}
                    style={{ width: "20%" }}
                  >
                    {value}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {lotteryState.lotteryResultUI ? (
        <>
          <div className="row justify-content-center">
            {lotteryState.lotteryResultUI.lotteryResultUI.map(
              (lotteryResult, index) => (
                <LotteryCard key={index} lotteryResult={lotteryResult} />
              )
            )}
          </div>
          <div
            className="mt-3 d-flex align-items-center justify-content-end text-muted pe-2"
            style={{ height: "30px" }}
          >
            {lotteryState.listLoading && (
              <div
                className="spinner-border me-2"
                role="status"
                style={{ scale: "0.45" }}
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            )}

            <div>
              {intl.formatMessage({ id: "refreshIn" })} {timer}s
            </div>
          </div>
        </>
      ) : (
        <Skeleton className="w-100" height="600px" />
      )}
    </>
  );
};
