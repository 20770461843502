/* eslint-disable jsx-a11y/anchor-is-valid */
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import { LoginRegisterDialog } from "../../modules/auth/components/LoginRegisterDialog";
import { LoginTab } from "../../modules/auth/enum/LoginTab";
import { TelegramListing } from "../../modules/telegram/component/TelegramListing";

const AuthWrapper = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const platform = queryParams.get("platform");

  return (
    <Routes>
      {platform === "telegram" ? (
        <Route path="*" element={<TelegramListing />} />
      ) : (
        <Route element={<Outlet />}>
          <Route path="*" element={<Navigate to="/login" />} />
          <Route
            path="/login"
            element={<LoginRegisterDialog initialTab={LoginTab.LOGIN} />}
          />
          <Route
            path="/credential/:credential"
            element={<LoginRegisterDialog initialTab={LoginTab.LOGIN} />}
          />
          <Route
            path="/register"
            element={
              <LoginRegisterDialog initialTab={LoginTab.REGISTER_OPTION} />
            }
          />
          <Route
            path="/register/:refferalCode"
            element={
              <LoginRegisterDialog initialTab={LoginTab.REGISTER_OPTION} />
            }
          />
          <Route index element={<LoginRegisterDialog />} />
        </Route>
      )}
    </Routes>
  );
};

export { AuthWrapper };
