import axios from "axios";
import { ApiStatusTypeEnum } from "../../../enumeration/ApiStatusTypeEnum";
import { appIntl } from "../../../i18n/IntlGlobalProvider";
import {
  getAuth,
  getDatabaseName,
  getLoginId,
  getMemberId,
} from "../../auth/redux/LoginAction";

const checkUserDataExist = () => {
  if (!getAuth()) {
    redirectBackToLogin();
  }
};

const redirectBackToLogin = () => {
  window.location.href = "/login";
};

export const authPost = async (endPoint, data) => {
  checkUserDataExist();

  return axios
    .post(`${window.location.protocol}//web.279rc.com/api/index.php`, {
      url: endPoint,
      data: {
        ...data,
        memberid: getMemberId(),
        loginid: getLoginId(),
        databasename: getDatabaseName(),
      },
    })
    .then((resp) => {
      if (parseInt(resp.data.code) === ApiStatusTypeEnum.SESSION_LOST) {
        alert(appIntl().formatMessage({ id: "sessionLost" }));
        localStorage.clear();
        window.location.href = window.location.origin;
      } else {
        return resp;
      }
    })
    .catch((error) => handleError(error));
};

export const authFormPost = async (endPoint, data) => {
  checkUserDataExist();

  return axios
    .postForm(endPoint, data)
    .then((resp) => {
      if (parseInt(resp.data.code) === ApiStatusTypeEnum.SESSION_LOST) {
        alert(appIntl().formatMessage({ id: "sessionLost" }));
        localStorage.clear();
        window.location.href = window.location.origin;
      } else {
        return resp;
      }
    })
    .catch((error) => handleError(error));
};

export const authGet = async (endPoint, data) => {
  checkUserDataExist();
  const params = Object.entries({
    ...data,
    memberid: getMemberId(),
    loginid: getLoginId(),
    databasename: getDatabaseName(),
  })
    .map(([key, value]) => `${key}=${value}`)
    .join("&");

  return axios
    .post(`${window.location.protocol}//web.279rc.com/api/getApi.php`, {
      url: `${endPoint}?${params}`,
    })
    .then((resp) => {
      if (parseInt(resp.data.code) === ApiStatusTypeEnum.SESSION_LOST) {
        alert(appIntl().formatMessage({ id: "sessionLost" }));
        localStorage.clear();
        window.location.href = window.location.origin;
      } else {
        return resp;
      }
    })
    .catch((error) => handleError(error));
};

export const doGet = async (endPoint, data) => {
  const params = Object.entries({
    ...data,
  })
    .map(([key, value]) => `${key}=${value}`)
    .join("&");

  return axios
    .post(`${window.location.protocol}//media.279rc.com/api/getApi.php`, {
      url: `${endPoint}?${params}`,
    })
    .then((resp) => {
      if (parseInt(resp.data.code) === ApiStatusTypeEnum.SESSION_LOST) {
        alert(appIntl().formatMessage({ id: "sessionLost" }));
      } else {
        return resp;
      }
    })
    .catch((error) => handleError(error));
};

export const doPost = async (endPoint, data) => {
  return axios
    .post(`${window.location.protocol}//web.279rc.com/api/index.php`, {
      url: endPoint,
      data: data,
    })
    .catch((error) => handleError(error));
};

export const handleError = (error) => {
  if (!(error.message && error.message === "canceled")) {
    alert(appIntl().formatMessage({ id: "errorOccurContactAdmin" }));
  }
};
